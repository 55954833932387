<template>
    
    <!-- Structure -->
    <v-container fluid>
        
        <!-- Barre d'outils -->
        <v-toolbar class="mb-5">
            <v-badge color="blue" class="hidden-xs-only" :content="items.length" offset-y="15" :value="items.length">
                <v-toolbar-title> 
                    Utilisateurs
                </v-toolbar-title>
            </v-badge>
            <v-spacer class="hidden-xs-only"></v-spacer>
            <v-text-field
                append-icon="search"
                clearable
                hide-details
                label="Rechercher"
                single-line
                v-model="search">
            </v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="add_del_update_dialog" :width="dialog_width">
                <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                color="blue"
                                dark
                                icon
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog }">
                                    <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Nouvel élément</span>
                    </v-tooltip>
                </template>
                <v-card>
                    <v-card-title>
                        {{ collection_header }} : {{ edit_index === -1 ? 'ajout d\'un élément' : (edit_action === 'delete' ? 'suppression d\'un document' : (edit_action === 'desactivate' ? 'désactivation d\'un document' : (edit_action === 'reactivate' ? 'réactivation d\'un document' : 'modification d\'un document')))}}
                    </v-card-title>
                    <v-card-text>
                        <v-alert border="left" dense dismissible text transition="fade-transition" type="error" v-if="edit_action !== 'delete'">
                            Validation de formulaire : à créer
                        </v-alert>
                        <span v-if="edit_action === 'delete'">Voulez-vous vraiment supprimer le document {{ edit_item.email }} ?</span>
                        <span v-if="edit_action === 'desactivate'">Voulez-vous vraiment désactiver le document {{ edit_item.email }} ?</span>
                        <span v-if="edit_action === 'reactivate'">Voulez-vous vraiment réactiver le document {{ edit_item.email }} ?</span>
                        <v-form
                            lazy-validation
                            name="add_edit_form"
                            ref="add_edit_form"
                            v-if="edit_index === -1 || edit_action === 'update'"
                            @submit="add_del_update_save">
                                <v-text-field
                                    label="NOM"
                                    name="name"
                                    required
                                    v-model="edit_item.name">
                                </v-text-field>
                                <v-text-field
                                    label="Prénom"
                                    name="first_name"
                                    required
                                    v-model="edit_item.first_name">
                                </v-text-field>
                                <v-text-field
                                    label="Mail"
                                    name="email"
                                    required
                                    v-model="edit_item.email">
                                </v-text-field>
                                <v-select 
                                    dense
                                    hide-details
                                    :items="roles"
                                    label="Rôle"
                                    name="role"
                                    outlined
                                    required
                                    v-model="edit_item.role_id.role">
                                </v-select>
                                <v-checkbox
                                    disabled
                                    label="Actif"
                                    name="active"
                                    required
                                    v-if="edit_index !== -1"
                                    v-model="edit_item.active">
                                </v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" dark text v-if="edit_index === -1 || edit_action === 'update'" @click="add_del_update_cancel">Annuler</v-btn>
                        <v-btn color="blue" dark text v-if="edit_index === -1 || edit_action === 'update'" @click="add_del_update_save">Enregistrer</v-btn>
                        <v-btn color="blue" dark text v-if="edit_index !== -1 && edit_action !== 'update'" @click="add_del_update_save">Oui</v-btn>
                        <v-btn color="blue" dark text v-if="edit_index !== -1 && edit_action !== 'update'" @click="add_del_update_cancel">Non</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        
        <!-- Collection -->
        <v-row>
            <v-col>
                <v-card :loading="loading">
                    <v-card-text>
                        <v-alert 
                            border="left"
                            dense 
                            dismissible
                            text 
                            transition="fade-transition"
                            type="error"
                            v-model="error_alert">
                                {{ error }}
                        </v-alert>
                        <v-alert 
                            border="left"
                            dense 
                            dismissible
                            text 
                            transition="fade-transition"
                            type="warning"
                            v-model="warning_alert">
                                {{ warning }}
                        </v-alert>
                        <v-alert 
                            border="left"
                            dense 
                            dismissible
                            text 
                            transition="fade-transition"
                            type="success"
                            v-model="message_alert">
                                {{ message }}
                        </v-alert>
                        <v-data-table
                            dense
                            :headers="headers" 
                            :items="items"
                            :search="search"
                            v-if="!loading">
                            <template v-slot:top></template>
                            <template v-slot:item.created_at="{ item }">
                                {{ item.created_at | date_format }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon class="mr-2" small @click="edit(item, 'update')">mdi-pencil</v-icon>
                                <v-icon class="mr-2" small @click="edit(item, 'delete')">mdi-delete</v-icon>
                                <v-icon class="mr-2" :disabled="item.active" small @click="edit(item, 'reactivate')">mdi-account-reactivate</v-icon>
                                <v-icon :disabled="!item.active" small @click="edit(item, 'desactivate')">mdi-account-off</v-icon>
                            </template>
                            <template v-slot:no-results>
                                <v-alert border="left" class="mt-5" dense text transition="fade-transition" type="error">
                                    Votre recherche <kbd>{{ search }}</kbd> n'a donné aucun résultat.
                                </v-alert>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    
    </v-container>
    
</template>

<script>

import user_service from "../services/user.service";

var error_alert_timer;
var warning_alert_timer;
var message_alert_timer;

export default {
    name: "users",
    computed: {
        dialog_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '90%'
                case 'sm': return '80%'
                case 'md': return '75%'
                case 'lg': return '70%'
                case 'xl': return '50%'
                default: return '75%'
            }
        }
    },
    data() {
        return {
            add_del_update_dialog: false,
            collection: 'users',
            collection_header: 'Utilisateurs',
            current_item_id: {},
            items: [],
            items_length: 0,
            edit_action: '',
            edit_item: {active: false, role_id: {role: ''}},
            edit_index: -1,
            error: '',
            error_alert: false,
            headers: [
                {
                    text: 'NOM',
                    align: 'start',
                    filterable: true,
                    value: 'name',
                },
                { text: 'Prénom', value: 'first_name' },
                { text: 'Courriel', value: 'email' },
                { text: 'Créé(e) le', value: 'created_at' },
                { text: 'Rôle', value: 'role_id.role' },
                {
                    text: 'Actions',
                    align: 'end',
                    sortable: false, 
                    value: 'actions'
                }
            ],
            loading: true,
            message: '',
            message_alert: false,
            roles: [],
            search: '',
            show_password: false,
            warning: '',
            warning_alert: false,
        };
    },
    filters: {
        date_format: function (value) {
            var date = new Date(value);
            return date ? date.toLocaleString() : value
        }
    },    
    methods: {
        add(item) {
            user_service.add_item(this.collection, item).then(response => {
                this.message = response.data.message;
                this.alert_display('message');
                this.initialize();
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
                this.initialize();
            });
        },
        add_del_update_cancel() {
            this.add_del_update_dialog=false;
            this.current_item_email={};
            this.edit_action='';
            this.edit_item={active: false, role_id: {role: ''}};
            this.edit_index = -1;
        },
        add_del_update_save() {
            this.add_del_update_dialog=false;
            this.loading=true;
            this.clear_alert_display('error');
            this.clear_alert_display('warning');
            this.clear_alert_display('message');
            if (this.edit_index === -1){
                this.add(this.edit_item);
            } else {
                switch(this.edit_action) {
                    case 'delete' :
                        this.del(this.current_item_email);
                        break;
                    case 'desactivate' :
                        this.desactivate(this.current_item_email);
                        break;
                    case 'reactivate' :
                        this.reactivate(this.current_item_email);
                        break;
                    case 'update' :
                        this.update(this.current_item_email, this.edit_item);
                        break;
                } 
            }
        },
        alert_display(alert_type) {
            switch(alert_type) {
                case 'error' :
                    this.error_alert = true;
                    error_alert_timer = setTimeout(() => (this.error_alert = false), 5000);
                    break;
                case 'warning' :
                    this.warning_alert = true;
                    warning_alert_timer = setTimeout(() => (this.warning_alert = false), 5000);
                    break;
                case 'message' :
                    this.message_alert = true;
                    message_alert_timer = setTimeout(() => (this.message_alert = false), 5000);
                    break;
            }
        },
        clear_alert_display(alert_type) {
            switch(alert_type) {
                case 'error' :
                    this.error_alert = false;
                    clearTimeout(error_alert_timer);
                    break;
                case 'warning' :
                    this.warning_alert = false;
                    clearTimeout(warning_alert_timer);
                    break;
                case 'message' :
                    this.message_alert = false;
                    clearTimeout(message_alert_timer);
                    break;
            }
        },
        del(item) {
            user_service.del_item(this.collection, item).then(response => {
                this.message = response.data.message;
                this.alert_display('message'); 
                this.initialize();
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
                this.initialize();
            });
        },
        desactivate(item) {
            user_service.desactivate_item(this.collection, item).then(response => {
                this.message = response.data.message;
                this.alert_display('message'); 
                this.initialize();
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
                this.initialize();
            });
        },
        edit(item, action) {
            switch (action) {
                case 'add' :
                    this.edit_action = 'add';
                    break;
                case 'delete' :
                    this.edit_action = 'delete';
                    this.edit_index = this.items.indexOf(item);
                    this.current_item_email = {email: item.email};
                    this.edit_item = Object.assign({}, item);
                    this.add_del_update_dialog = true;
                    break;
                case 'desactivate' :
                    this.edit_action = 'desactivate';
                    this.edit_index = this.items.indexOf(item);
                    this.current_item_email = {email: item.email};
                    this.edit_item = Object.assign({}, item);
                    this.add_del_update_dialog = true;
                    break;
                case 'reactivate' :
                    this.edit_action = 'reactivate';
                    this.edit_index = this.items.indexOf(item);
                    this.current_item_email = {email: item.email};
                    this.edit_item = Object.assign({}, item);
                    this.add_del_update_dialog = true;
                    break;
                case 'update' :
                    this.edit_action = 'update';
                    this.edit_index = this.items.indexOf(item);
                    this.current_item_email = {email: item.email};
                    this.edit_item = Object.assign({}, item);
                    this.add_del_update_dialog = true;
                    break;
            }
        },
        initialize() {
            this.current_item_email={};
            this.edit_action='';
            this.edit_item={active: false, role_id: {role: ''}};
            this.edit_index = -1;
            this.loading=true;
            user_service.get_all(this.collection).then(response => {
                if (!response.data.message) {
                    this.items = response.data;
                    this.items_length = this.items.length;
                    this.loading = false;
                } else {
                    this.message = response.data.message;
                    this.alert_display('message');
                }
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
            });
            user_service.get_all('roles').then(response => {
                this.roles.length = 0;
                if (!response.data.message) {
                    for (let document of response.data) {
                        this.roles.push(document.role);
                    }
                } else {
                    this.message = response.data.message;
                    this.alert_display('message');
                }
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
            });
        },
        reactivate(item) {
            user_service.reactivate_item(this.collection, item).then(response => {
                this.message = response.data.message;
                this.alert_display('message'); 
                this.initialize();
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
                this.initialize();
            });
        },
        update(old_item, new_item) {
            user_service.update_item(this.collection, old_item, new_item).then(response => {
                this.message = response.data.message;
                this.alert_display('message');
                this.initialize();
            },
            error => {
                if(error.response.data.error){
                    this.error = 
                        (error.response && error.response.data.error) ||
                        error.message ||
                        error.toString();
                    this.alert_display('error');
                } else {
                    this.warning = 
                        (error.response && error.response.data.warning) ||
                        error.message ||
                        error.toString();
                    this.alert_display('warning');
                }
                this.initialize();
            });
        }
    },
    mounted() {
        this.initialize();
    }
};
</script>

<style>

</style>
